(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function aboutDecorationAnimation() {
  var isMax991px = window.matchMedia("(max-width: 991px)").matches;
  var observerTarget = document.querySelector('[data-element="about-decoration"]');
  if (!isMax991px && observerTarget) {
    var handleMutation = function handleMutation(mutation) {
      var target = mutation[0].target;
      if (target.classList.contains("aos-animate")) {
        observer.disconnect();
        setTimeout(function () {
          observerTarget.classList.add("active");
        }, 380);
      }
    };
    var observer = new MutationObserver(handleMutation);
    observer.observe(observerTarget, {
      attributes: true
    });
  }
}
var _default = exports["default"] = aboutDecorationAnimation;

},{}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.categoriesBtnsMobile = categoriesBtnsMobile;
exports.updateBtnsState = updateBtnsState;
var btnPrev = document.querySelector('[data-categories="btn-prev"]');
var btnNext = document.querySelector('[data-categories="btn-next"]');
var options = document.querySelectorAll('[data-categories="option"]');
function findIndexOptionActive() {
  var optionActiveIndex = Array.from(options).findIndex(function (option) {
    if (option.classList.contains("active")) return option;
  });
  return optionActiveIndex;
}
function updateBtnsState() {
  // btnPrev
  if (findIndexOptionActive() === 0) {
    btnPrev.classList.add("disabled");
  } else if (btnPrev.classList.contains("disabled")) {
    btnPrev.classList.remove("disabled");
  }

  // btnNext
  if (findIndexOptionActive() + 1 === options.length) {
    btnNext.classList.add("disabled");
  } else if (btnNext.classList.contains("disabled")) {
    btnNext.classList.remove("disabled");
  }
}
function categoriesBtnsMobile() {
  function goToPrevCategory() {
    var indexTarget = findIndexOptionActive();
    options[indexTarget - 1].parentElement.classList.remove("hidden");
    options[indexTarget - 1].click();
  }
  function goToNextCategory() {
    var indexTarget = findIndexOptionActive();
    options[indexTarget].parentElement.classList.add("hidden");
    options[indexTarget + 1].click();
  }
  btnPrev.addEventListener("click", goToPrevCategory);
  btnNext.addEventListener("click", goToNextCategory);
}

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _categoriesBtnsMobile = require("./categoriesBtnsMobile.js");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function categoriesNavTab() {
  var options = document.querySelectorAll('[data-categories="option"]');
  var contents = document.querySelectorAll('[data-categories="content"]');
  if (options.length && contents.length) {
    var findIndexOptionActive = function findIndexOptionActive() {
      var optionActiveIndex = Array.from(options).findIndex(function (option) {
        if (option.classList.contains("active")) return option;
      });
      return optionActiveIndex;
    };
    var hidePrevsOptions = function hidePrevsOptions() {
      var indexTarget = findIndexOptionActive();
      for (var index = 0; index < indexTarget; index++) {
        options[index].parentElement.classList.add("hidden");
      }
    };
    var updateCategoryContent = function updateCategoryContent(indexTarget) {
      if (options[indexTarget].classList.contains("active")) return;
      [].concat(_toConsumableArray(options), _toConsumableArray(contents)).forEach(function (item) {
        return item.classList.remove("active");
      });
      options[indexTarget].classList.add("active");
      contents[indexTarget].classList.add("active");
      (0, _categoriesBtnsMobile.updateBtnsState)();
      hidePrevsOptions();
    };
    options.forEach(function (option, index) {
      option.addEventListener("click", function (event) {
        updateCategoryContent(index);
      });
    });
  }
}
var _default = exports["default"] = categoriesNavTab;

},{"./categoriesBtnsMobile.js":2}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function headerFixed() {
  var header = document.querySelector('[data-header="header"]');
  if (header) {
    var handleWindowScroll = function handleWindowScroll() {
      var windowScroll = window.scrollY;
      if (windowScroll > 550) {
        header.classList.add("fixed");
      } else if (header.classList.contains("fixed")) {
        header.classList.remove("fixed");
      }
    };
    handleWindowScroll();
    window.addEventListener("scroll", handleWindowScroll);
  }
}
var _default = exports["default"] = headerFixed;

},{}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function headerInternLinks() {
  var internLinks = document.querySelectorAll(".intern-link a[href^=\"#\"]");
  var isMax991px = window.matchMedia("(max-width: 991px)").matches;
  if (isMax991px && internLinks.length) {
    var scrollToSection = function scrollToSection() {
      var headerBtnMobile = document.querySelector('[data-header="btn-mobile"]');
      headerBtnMobile.click();
    };
    internLinks.forEach(function (link) {
      link.addEventListener("click", scrollToSection);
    });
  }
}
var _default = exports["default"] = headerInternLinks;

},{}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function headerLinkActiveOnScroll() {
  var sections = document.querySelectorAll("[data-section]");
  var linksIntern = document.querySelectorAll('[data-header="nav"] .intern-link');
  if (sections.length) {
    var handleScroll = function handleScroll() {
      sections.forEach(function (section) {
        var sectionTarget = section[0] ? section[0] : section;
        if (sectionTarget) {
          var sectionTop = sectionTarget.getBoundingClientRect().top;
          var isTopSectionVisible = sectionTop - scroll40Percentage < 0;
          var dataSection = sectionTarget.dataset.section;
          if (isTopSectionVisible && !sectionTarget.classList.contains("active")) {
            sectionTarget.classList.add("active");
            if (dataSection) {
              var linkTarget = document.querySelector("[data-header=\"nav\"] .link-".concat(dataSection));
              linksIntern.forEach(function (link) {
                if (link === linkTarget) {
                  link.classList.add("active");
                } else if (link.classList.contains("active")) {
                  link.classList.remove("active");
                }
              });
            } else {
              linksIntern.forEach(function (link) {
                return link.classList.remove("active");
              });
            }
          } else if (!isTopSectionVisible && sectionTarget.classList.contains("active")) {
            sectionTarget.classList.remove("active");
            var sectionsUpdated = document.querySelectorAll("[data-section]");
            var sectionsActive = Array.from(sectionsUpdated).filter(function (section) {
              if (section.classList.contains("active")) return section;
            });
            var lastSectionActive = sectionsActive[sectionsActive.length - 1];
            var lastSectionActiveData = lastSectionActive.dataset.section;
            if (lastSectionActiveData) {
              var _linkTarget = document.querySelector(".link-".concat(lastSectionActiveData));
              linksIntern.forEach(function (link) {
                if (link === _linkTarget) {
                  link.classList.add("active");
                } else if (link.classList.contains("active")) {
                  link.classList.remove("active");
                }
              });
            } else {
              linksIntern.forEach(function (link) {
                return link.classList.remove("active");
              });
            }
          }
        }
      });
    };
    var scroll40Percentage = window.innerHeight * 0.4;
    handleScroll();
    window.addEventListener("scroll", handleScroll);
  }
}
var _default = exports["default"] = headerLinkActiveOnScroll;

},{}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function headerMobile() {
  // btn mobile
  var headerBtnMobile = document.querySelector('[data-header="btn-mobile"]');
  if (headerBtnMobile) {
    var toggleHeaderNavMobile = function toggleHeaderNavMobile(event) {
      event.preventDefault();
      html.classList.toggle("overflow-mobile");
      headerNav.classList.toggle("active");
      headerBtnMobile.classList.toggle("active");
      headerOverlay.classList.toggle("active");
    };
    var html = document.documentElement;
    var headerNav = document.querySelector('[data-header="nav"]');
    var headerOverlay = document.querySelector('[data-header="overlay"]');
    headerBtnMobile.addEventListener("click", toggleHeaderNavMobile);
    headerOverlay.addEventListener("click", toggleHeaderNavMobile);
  }
}
var _default = exports["default"] = headerMobile;

},{}],8:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function heroChangeMessagesMobile() {
  var messages = document.querySelectorAll('[data-hero="topics"] li');
  var isMax599px = window.matchMedia("(max-width: 599px)").matches;
  if (messages && isMax599px) {
    var findIndexMessageActive = function findIndexMessageActive() {
      var optionActiveIndex = Array.from(messages).findIndex(function (option) {
        if (option.classList.contains("active")) return option;
      });
      return optionActiveIndex;
    };
    setInterval(function () {
      var messageActiveIndex = findIndexMessageActive();
      var indexElementToActive = null;
      if (messageActiveIndex === messages.length - 1) {
        indexElementToActive = 0;
      } else {
        indexElementToActive = messageActiveIndex + 1;
      }
      messages.forEach(function (message) {
        return message.classList.remove("active");
      });
      messages[indexElementToActive].classList.add("active");
    }, 5000);
  }
}
var _default = exports["default"] = heroChangeMessagesMobile;

},{}],9:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function scrollToPage() {
  var links = document.querySelectorAll('[data-link="scrollTop"]');
  if (links.length) {
    var scrollToTop = function scrollToTop(event) {
      event.preventDefault();
      window.scrollTo(0, 0);
    };
    links.forEach(function (link) {
      link.addEventListener("click", scrollToTop);
    });
  }
}
var _default = exports["default"] = scrollToPage;

},{}],10:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function testimonialsSlider() {
  var testimonialsBox = $('[data-testimonials="slider"]');
  if (testimonialsBox.length) {
    var slider = new Swiper('[data-testimonials="slider"]', {
      // effect: "fade",
      spaceBetween: 10,
      speed: 400,
      pagination: {
        el: '[data-testimonials="pagination"]',
        type: "fraction"
      },
      navigation: {
        nextEl: '[data-testimonials="btn-next"]',
        prevEl: '[data-testimonials="btn-prev"]'
      }
    });
  }
}
var _default = exports["default"] = testimonialsSlider;

},{}],11:[function(require,module,exports){
"use strict";

var _headerInternLinks = _interopRequireDefault(require("./modules/headerInternLinks.js"));
var _headerFixed = _interopRequireDefault(require("./modules/headerFixed.js"));
var _headerMobile = _interopRequireDefault(require("./modules/headerMobile.js"));
var _headerLinkActiveOnScroll = _interopRequireDefault(require("./modules/headerLinkActiveOnScroll.js"));
var _aboutDecorationAnimation = _interopRequireDefault(require("./modules/aboutDecorationAnimation.js"));
var _categoriesNavTab = _interopRequireDefault(require("./modules/categoriesNavTab.js"));
var _categoriesBtnsMobile = require("./modules/categoriesBtnsMobile.js");
var _scrollTopPage = _interopRequireDefault(require("./modules/scrollTopPage.js"));
var _testimonialsSlider = _interopRequireDefault(require("./modules/testimonialsSlider.js"));
var _heroChangeMessagesMobile = _interopRequireDefault(require("./modules/heroChangeMessagesMobile.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// Quando ALGUMAS partes da página carregar
$(document).ready(function () {
  (0, _headerInternLinks["default"])();
  (0, _headerFixed["default"])();
  (0, _headerMobile["default"])();
  (0, _headerLinkActiveOnScroll["default"])();
  (0, _heroChangeMessagesMobile["default"])();
  (0, _aboutDecorationAnimation["default"])();
  (0, _categoriesNavTab["default"])();
  (0, _categoriesBtnsMobile.categoriesBtnsMobile)();
  (0, _scrollTopPage["default"])();
  (0, _testimonialsSlider["default"])();
});

// Quando TODA a página carregar
window.addEventListener("load", function () {
  var body = document.body;
  body.classList.add("page-loaded");
});

},{"./modules/aboutDecorationAnimation.js":1,"./modules/categoriesBtnsMobile.js":2,"./modules/categoriesNavTab.js":3,"./modules/headerFixed.js":4,"./modules/headerInternLinks.js":5,"./modules/headerLinkActiveOnScroll.js":6,"./modules/headerMobile.js":7,"./modules/heroChangeMessagesMobile.js":8,"./modules/scrollTopPage.js":9,"./modules/testimonialsSlider.js":10}]},{},[11]);
